import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

export default class extends Controller {
  connect() {
    const swiper = new Swiper('.swiper', {
      modules: [Pagination],
      direction: 'horizontal',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });

    let fraction = document.getElementById('swiper_fraction');
    updateFraction(fraction, swiper);

    swiper.on('slideChange', function(event) {
      updateFraction(fraction, swiper);
    });
  }
}

function updateFraction(fraction, swiper) {
  fraction.innerHTML = (swiper.activeIndex + 1) + '/' + swiper.slides.length;
}
