import Alpine from 'alpinejs';

function initializeDropdownContent(state) {
  return {
    state: state,
    cities: [],
    counties: [],
    fetchCitiesAndCounties() {
      url = new URL('/schools/addresses/cities_and_counties_for_state', window.location.origin)
      url.searchParams.append('state', this.state)
      fetch(url).then(res => res.json()).then((data) => { this.cities = data.cities; this.counties = data.counties })
    },
    onStateChange() {
      this.fetchCitiesAndCounties();
    },
    init() {
      this.fetchCitiesAndCounties();
    }
  }
}

Alpine.data('initializeDropdownContent', initializeDropdownContent);
