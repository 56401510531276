import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.modalTurboFrame.removeAttribute('src');
  }

  // eslint-disable-next-line class-methods-use-this
  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
