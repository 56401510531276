import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('sponsors', {
    checkboxes: {},
  });
});

function selectSponsorItems(initialCheckedValue, itemId) {
  return {
    disabled: !initialCheckedValue,
    itemId,

    change(event) {
      const numberInput = document.getElementById(`legacy_sponsor_legacy_sponsor_items_attributes_${this.itemId}_quantity`);
      const destroyInput = document.getElementById(`legacy_sponsor_legacy_sponsor_items_attributes_${this.itemId}__destroy`);

      this.disabled = !event.target.checked;
      if (event.target.checked) {
        numberInput.setAttribute('min', 1);
        numberInput.value = 1;
        destroyInput.value = 'false';
      } else {
        numberInput.setAttribute('min', 0);
        numberInput.value = 0;
        destroyInput.value = 'true';
      }
    },

    plus() {
      const input = document.getElementById(`legacy_sponsor_legacy_sponsor_items_attributes_${this.itemId}_quantity`);
      input.value = parseInt(input.value, 10) + 1;
    },

    minus() {
      const input = document.getElementById(`legacy_sponsor_legacy_sponsor_items_attributes_${this.itemId}_quantity`);
      if (input.value > 1) {
        input.value -= 1;
      }
    },
  };
}

Alpine.data('selectSponsorItems', selectSponsorItems);
