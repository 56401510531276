import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';

document.addEventListener('turbo:load', () => {
  const active = document.querySelector('meta[name=mixpanel_project_token]')?.content;

  if (!active || active === 'false') {
    return;
  }

  const mixpanelToken = document.querySelector('meta[name=mixpanel_project_token]').content;
  const cookieConsent = Cookies.get('cookieConsent') === 'true'
  mixpanel.init(mixpanelToken, { track_pageview: true, opt_out_tracking_by_default: cookieConsent });
  const currentUserId = Cookies.get('current_user_id');

  if (currentUserId) {
    mixpanel.identify(currentUserId)
  }
});

document.addEventListener('startTracking', () => {
  mixpanel.set_config({
    opt_out_tracking_by_default: true,
  });

  const currentUserId = Cookies.get('current_user_id');

  if (currentUserId) {
    mixpanel.identify(currentUserId)
  }
});
