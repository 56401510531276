/* eslint-disable no-param-reassign */
import { post } from '@rails/request.js';
import debounce from 'debounce';
import Alpine from 'alpinejs';

function mountLinkAuthenticationElement(elements, email) {
  const linkAuthenticationOptions = { defaultValues: { email } };
  const linkAuthenticationElement = elements.create('linkAuthentication', linkAuthenticationOptions);
  linkAuthenticationElement.mount('#link-authentication-element');
  return elements;
}

async function validateCard(event) {
  Alpine.store('validPaymentMethod', event.complete);
}

function paymentMethodOrder() {
  const paymentElement = document.getElementById('payment-element');
  if (paymentElement && paymentElement.dataset.order && paymentElement.dataset.order == 'us_bank_account') {
    return ['us_bank_account', 'card'];
  }
  return ['card', 'us_bank_account'];
}

function mountPaymentElement(elements) {
  const paymentElementOptions = {
    layout: 'tabs',
    paymentMethodOrder: paymentMethodOrder(),
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
      },
    },
  };
  const paymentElement = elements.create('payment', paymentElementOptions);
  paymentElement.mount('#payment-element');
  paymentElement.addEventListener('change', debounce(validateCard, 500));
  return elements;
}

async function calculateTax(event) {
  if (!event.complete) { return; }

  post('/sponsor_place/tax_calculation', {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
    },
  });
}

function mountBillingAddressElement(elements, name, calculatesTax) {
  const billingAddressOptions = {
    mode: 'billing',
    allowedCountries: ['US'],
    defaultValues: {
      name,
    },
  };
  const billingAddressElement = elements.create('address', billingAddressOptions);
  billingAddressElement.mount('#billing-address-element');

  if (calculatesTax) {
    billingAddressElement.addEventListener('change', debounce(calculateTax, 1500));
  }
  return elements;
}

function mountUIComponents(elements, email, name, calculatesTax) {
  elements = mountLinkAuthenticationElement(elements, email);
  elements = mountPaymentElement(elements);
  return mountBillingAddressElement(elements, name, calculatesTax);
}

// eslint-disable-next-line max-len
export default async function createElementsContainer(stripe, clientSecret, email, name, calculatesTax) {
  const appearance = {
    theme: 'stripe',
  };
  const elements = stripe.elements({ appearance, clientSecret });
  return mountUIComponents(elements, email, name, calculatesTax);
}
