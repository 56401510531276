import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'sportSelect',
    'assetTypeNew',
    'assetTypeEdit'
  ];

  static values = { sports: String };

  sportSelectTargetConnected(target) {
    if (isNaN(this.currentAssetTypeId)) {
      return;
    }
    this.processSportSelect(target);
  }

  assetTypeSelected(_event) {
    this.setAssetDefaultLeadTimeInDays(this.selectedAssetType.text);
    this.toggleAssetPrintingOptions(this.selectedAssetType.dataset.printing == 'true');
    this.updateSportSelectOptions();
  }

  setAssetDefaultLeadTimeInDays(selectedAssetTypeName) {
    if (document.getElementById('asset_assets_attributes_0_marquee_attributes_lead_time_in_days')) {
      if (selectedAssetTypeName == 'Marquee') {
        document.getElementById('asset_assets_attributes_0_marquee_attributes_lead_time_in_days').value = 7;
      } else {
        document.getElementById('asset_assets_attributes_0_marquee_attributes_lead_time_in_days').value = '';
      }
    }
  }

  toggleAssetPrintingOptions(selectedAssetTypeNeedsPrinting) {
    if (document.getElementById('printing_options')) {
      document.getElementById('printing_options').hidden = !selectedAssetTypeNeedsPrinting
    }
  }

  updateSportSelectOptions() {
    const sportSelect = document.getElementsByClassName('sport_select')[0];
    this.processSportSelect(sportSelect);
  }

  processSportSelect(select) {
    const options = select.options;
    this.filterShownSportOptions(options);

    if (this.availableSportsForSelectedAssetType) {
      const selectedSportIsAvailable = this.availableSportsForSelectedAssetType.find((sport) => sport == select.value)
      if (!selectedSportIsAvailable) {
        select.value = "";
        this.unselectOptions(options);
      }
    }

    const event = new Event('change');

    select && select.dispatchEvent(event);
  }

  filterShownSportOptions(options) {
    const availableSportsForSelectedAssetType = this.availableSportsForSelectedAssetType
    for (let j = 0; j < options.length; ++j) {
      const option = options.item(j);

      if (!option) continue;

      this.toggleOption(option, availableSportsForSelectedAssetType);
    }
  }

  unselectOptions(options) {
    for (let j = 0; j < options.length; ++j) {
      const option = options.item(j);

      if (!option) continue;

      option.selected = false;
    }
  }

  toggleOption(option, availableSportsForSelectedAssetType) {
    const sportIsAvailableForSelectedAssetType = availableSportsForSelectedAssetType?.find((sport) => sport == option.value) !== undefined
    if (sportIsAvailableForSelectedAssetType) {
      this.showOption(option);
    } else {
      this.hideOption(option);
    }
  }

  showOption(option) {
    option.classList.remove('hidden');
    option.disabled = false;
  }

  hideOption(option) {
    option.classList.add('hidden');
    option.disabled = true;
  }

  get availableSportsForSelectedAssetType() {
    return this.availableSportIdsByAssetTypeId && this.availableSportIdsByAssetTypeId[this.currentAssetTypeId];
  }

  get availableSportIdsByAssetTypeId() {
    this.sportsGrouppedByAssetType ||= JSON.parse(this.sportsValue);

    return this.sportsGrouppedByAssetType;
  }

  get selectedAssetType() {
    const selectedAssetType = this.hasAssetTypeNewTarget ? this.assetTypeNewTarget.selectedOptions[0] : this.assetTypeEditTarget
    return selectedAssetType;
  }

  get currentAssetTypeId() {
    return parseInt(this.selectedAssetType.value);
  }
}
