import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('assetsToBeRenewed', {
    subtotal: 0,
    count: 0,
  });
});

function selectRenewalAssetsData() {
  return {
    onValueChange(event) {
      const { target } = event;
      const value = target.checked;
      const price = target.dataset.price;
      const index = target.dataset.index;
      const itemType = target.dataset.itemType;
      const fieldNames = this.fieldNames(itemType);

      if (value) {
        this.subtotal = this.subtotal + parseFloat(price);
        this.count = this.count + parseInt(1);

        fieldNames.forEach((name) => {
          this.setCartDisabled(index, name, false);
        });

      } else {
        this.subtotal = this.subtotal - price;
        this.count = this.count - 1;

        fieldNames.forEach((name) => {
          this.setCartDisabled(index, name, true);
        });
      }
    },

    fieldNames(itemType) {
      if (itemType == 'Asset') {
        return ['additional_information', 'item_id', 'item_type', 'price_per_unit', 'purchased_asset_id', 'quantity', 'relationship_with_school', 'reuse_creative'];
      } else {
        return ['additional_information', 'item_id', 'item_type', 'price_per_unit', 'purchased_package_id', 'quantity', 'relationship_with_school', 'reuse_creative'];
      }
    },

    setCartDisabled(index, name, value) {
      document.getElementById(`shopping_cart_cart_items_attributes_${index}_${name}`).disabled = value;
    }
  };
}

Alpine.data('selectRenewalAssetsData', selectRenewalAssetsData);
