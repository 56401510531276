import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('assets', {
    checkboxes: {},
  });
});

function selectAllAssetsData() {
  return {
    selectAll: false,
    anyAssetSelected: false,
    allCheckboxes: [],

    toggleAllCheckboxes() {
      this.selectAll = !this.selectAll;

      checkboxes = document.querySelectorAll('input:not([disabled])[name^=asset]');
      [...checkboxes].map((checkbox) => {
        checkbox.checked = this.selectAll;
        const event = new Event('change');

        checkbox.dispatchEvent(event);
      });
    }
  };
}

function selectSportsData(assetId) {
  return {
    selectAll: false,
    indeterminate: false,
    checkboxes: [],
    total: assetId.querySelectorAll?.('input:not([disabled])[name^=assets]')?.length,

    toggleSports() {
      const selectAll = !this.selectAll;

      const checkboxes = assetId.querySelectorAll('input:not([disabled])[name^=assets]');
      [...checkboxes].map((el) => {
        el.checked = selectAll;
        const event = new Event('change');

        el.dispatchEvent(event);
      });
    },

    onValueChange(event) {
      const target = event.target;
      const value = target.checked;
      const id = target.value;

      this.checkboxes[id] = value;
      Alpine.store('assets').checkboxes[id] = value;
      const length = Object.keys(this.checkboxes).filter((k) => this.checkboxes[k]).length;

      if (length === this.total) {
        this.selectAll = length === this.total;
        this.indeterminate = false;
      } else if (length > 0) {
        this.selectAll = false;
        this.indeterminate = true;
      } else {
        this.selectAll = false;
        this.indeterminate = false;
      }
    }
  };
}

function assetRenewalData(hasRenewalDate) {
  return {
    renews: hasRenewalDate,

    toggleRenews(newValue) {
      this.renews = newValue;
      if (newValue) {
        if (document.getElementById('template_asset_assets_attributes_0_renewal_date')) {
          document.getElementById('template_asset_assets_attributes_0_renewal_date').value = ''
        };
        if (document.getElementById('asset_renewal_date')) {
          document.getElementById('asset_renewal_date').value = ''
        };
      }
    }
  }
}

function templateAssetData(selectedAssetTypeId, marqueeAssetTypeId) {
  return {
    asset_type_id: selectedAssetTypeId,

    get isMarquee() { return this.asset_type_id == marqueeAssetTypeId }
  }
}


Alpine.data('selectAllAssetsData', selectAllAssetsData);
Alpine.data('selectSportsData', selectSportsData);
Alpine.data('assetRenewalData', assetRenewalData);
Alpine.data('templateAssetData', templateAssetData);
