import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  change(event) {
    let assetIds = JSON.parse(event.target.value)

    this.toggleCheckboxes(event.target.checked, assetIds)
  }

  toggleCheckboxes(value, assetIds) {
    let checkbox;
    let event;
    for (let i = 0; i < assetIds.length; i++) {
      checkbox = document.getElementById('package_asset_ids_' + assetIds[i])

      checkbox.checked = value
      event = new Event('change');
      checkbox.dispatchEvent(event);
    }
  }
}
