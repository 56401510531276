import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
export default class extends Controller {

  static targets = ['assetTypeSelect', 'specificationSelect', 'sportSelect']

  sportSelected(event) {
    this.sportSelectChanged(event.target);
  }

  assetTypeSelected(event) {
    this.assetTypeSelectChanged(event.target);
  }

  assetTypeSelectChanged(target) {
    const selectedOption = target.selectedOptions[0];
    const assetTypeIsSelected = selectedOption && selectedOption.value && selectedOption.value != "";

    if (assetTypeIsSelected) {
      const assetTypeId = selectedOption.value;

      const specificationSelectId = this.specificationSelectTarget.id;
      const specificationSelectPresent = specificationSelectId && specificationSelectId != "";

      const sportSelectId = this.sportSelectTarget.id;
      const sportSelectPresent = sportSelectId && sportSelectId != "";

      if (sportSelectPresent && specificationSelectPresent) {
        this.loadDataAccordingToSelectedAssetType(assetTypeId, specificationSelectId, sportSelectId);
      };
    } else {
      this.removeOptions(this.specificationSelectTarget.options);
      this.removeOptions(this.sportSelectTarget.options);
    }
  }

  loadDataAccordingToSelectedAssetType(assetTypeId, specificationSelectId, sportSelectId) {
    const params = {
      specification_select_id: specificationSelectId,
      sport_select_id: sportSelectId,
      asset_type_id: assetTypeId,
      current_specification_id: this.specificationSelectTarget.value,
      current_sport_id: this.sportSelectTarget.value
    };

    Object.keys(params).forEach(key => params[key] === "" || params[key] === undefined && delete params[key]);
    const paramsString = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");

    get(`/schools/onboarding/assets/update_select_options?${paramsString}`, {
      responseKind: "turbo-stream"
    });
  }

  sportSelectChanged(target) {
    const selectedOption = target.selectedOptions[0];
    const sportIsSelected = selectedOption && selectedOption.value && selectedOption.value != "";

    if (sportIsSelected) {
      const sportId = selectedOption.value;

      const specificationSelectId = this.specificationSelectTarget.id;
      const specificationSelectPresent = specificationSelectId && specificationSelectId != ""

      const assetTypeId = this.assetTypeSelectTarget.value;
      const assetTypeIsSelected = assetTypeId && (assetTypeId != "");

      if (specificationSelectPresent && assetTypeIsSelected) {
        this.loadDataAccordingToSelectedSport(sportId, specificationSelectId, assetTypeId)
      };
    }
    else {
      this.removeOptions(this.specificationSelectTarget.options);
    }
  }

  loadDataAccordingToSelectedSport(sportId, specificationSelectId, assetTypeId) {
    const params = {
      specification_select_id: specificationSelectId,
      sport_id: sportId,
      asset_type_id: assetTypeId,
      current_specification_id: this.specificationSelectTarget.value
    };

    Object.keys(params).forEach(key => params[key] === "" || params[key] === undefined && delete params[key]);
    const paramsString = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");

    get(`/schools/onboarding/assets/update_select_options?${paramsString}`, {
      responseKind: "turbo-stream"
    });
  }

  removeOptions(options) {
    const length = options.length;
    for (let i = length; i >= 0; --i) {
      options.item(i)?.remove();
    }
  }
}
