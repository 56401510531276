/* eslint-disable class-methods-use-this */
// Reconnect ActionCable after switching accounts

import { Controller } from '@hotwired/stimulus';

import consumer from '../channels/consumer';

export default class extends Controller {
  reconnect(_event) {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen();
    }
  }
}
