import { Controller } from '@hotwired/stimulus';

import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import DragDrop from '@uppy/drag-drop';

export default class extends Controller {
  static targets = ['previewContainer', 'previewFileName', 'previewFileSize', 'hiddenInput', 'dragDrop', 'removeInput', 'downloadTemplate'];

  connect() {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['text/csv'],
      },
      locale: {
        strings: {
          browse: 'Click',
          dropHereOr: '%{browse} to upload or drag and drop',
        },
      },
    })
      .use(XHRUpload, {
        endpoint: '/upload',
      })
      .use(DragDrop, {
        target: this.dragDropTarget,
        note: 'CSV',
      });

    this.uppy.on('upload-success', (file, response) => {
      const fileSize = Math.round(file.size / 1000) + ' KB';
      this.fileId = file.id;
      this.updatePreview(file.name, fileSize);

      const uploadedFileData = response.body.data;
      this.updateData(file, JSON.stringify(uploadedFileData));
    });
  }

  updatePreview(fileName, fileSize) {
    if (this.hasPreviewFileNameTarget) {
      this.previewFileNameTarget.innerHTML = fileName;
    }
    if (this.hasPreviewFileSizeTarget) {
      this.previewFileSizeTarget.innerHTML = fileSize;
    }
    this.previewContainerTarget.classList.remove('hidden');
    this.dragDropTarget.style.display = 'none';
    this.downloadTemplateTarget.style.display = 'none';
  }

  updateData(_file, data) {
    this.hiddenInputTarget.value = data;
    this.removeInputTarget.value = 'false';
  }

  remove(e) {
    e.preventDefault();
    this.previewContainerTarget.classList.add('hidden');
    this.hiddenInputTarget.value = '';

    this.dragDropTarget.style.display = 'inline-block';
    this.downloadTemplateTarget.style.display = 'inline-block';

    this.uppy.removeFile(this.fileId);

    this.removeInputTarget.value = 'true';
  }

  changeFile(e) {
    e.preventDefault();

    this.remove(e);
  }
}
