import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  scrollToTarget(event) {
    const scrollTargetId = event.target.dataset.targetId
    const scrollTarget = document.getElementById(scrollTargetId)
    window.scrollTo({
      top: scrollTarget.getBoundingClientRect().top - 20,
      behavior: "smooth"
    })
    scrollTarget.focus()
  }
}
