import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('packageSteps', {
    changed: false,

    valueChanged() {
      this.changed = true;
    },
    reset() {
      this.changed = false;
    },
  });
});
