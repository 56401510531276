import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    this.select = new TomSelect(
      this.element,
      {
        optionClass: 'font-extrabold hover:text-primary-6 p-4 text-2xl',
        itemClass: 'font-extrabold text-2xl text-primary-6',
        maxItems: 1,
        items: this.selectedItem(this.element),
        render:{
          dropdown:function(){
            return '<div class="!text-2xl !z-40"></div>';
          }
        },
      },
    );
  }

  disconnect() {
    this.select.destroy();
  }

  selectedItem(element) {
    if (element.hasAttribute('selectedValue')) {
      return element.attributes.selectedValue.value;
    }
    return null;
  }
}
