// Rails functionality
import { Turbo } from '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import LocalTime from 'local-time';
import '@rails/actiontext';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import flatpickr from 'flatpickr';

import Cookies from 'js-cookie';

// ActionCable Channels
import './channels';

// Stimulus controllers
import './controllers';

// Jumpstart Pro & other Functionality
// eslint-disable-next-line import/no-unresolved
import './src/**/*';

import './stripe/checkout';
import './stripe/payment_method';
import './stripe/contribute';
import './mixpanel/setup';

import 'chartkick/chart.js';

import chartkick from 'chartkick';

// Make accessible for Electron and Mobile adapters
// @ts-ignore
window.Alpine = Alpine;
window.Rails = Rails;
window.Turbo = Turbo;
window.Cookies = Cookies;

Alpine.plugin(collapse);

Alpine.start();
ActiveStorage.start();
LocalTime.start();
Rails.start();

document.addEventListener('DOMContentLoaded', () => {
  flatpickr('.datepicker', {
    mode: 'range',
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (_selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace('to', '-');
      const customClass = instance.element.getAttribute('data-class') || '';
      instance.calendarContainer.classList.add(customClass);
    },
    onChange: (_selectedDates, dateStr, instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.element.value = dateStr.replace('to', '-');
    },
  });
});

if ('Chartkick' in window) {
  Chartkick.config.autoDestroy = false;

  window.addEventListener('turbo:before-render', () => {
    Chartkick.eachChart((chart) => {
      if (!chart.element.isConnected) {
        chart.destroy();
        delete chartkick.charts[chart.element.id];
      }
    });
  });
}
