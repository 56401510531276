import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    this.select = new TomSelect(
      this.element.querySelectorAll('select')[0],
      {
        maxItems: this.maxItems(this.element),
        items: this.selectedItem(this.element),
        plugins: {
          remove_button: {
            title: 'Remove this item',
            className: "text-white font-semibold px-2"
          }
        },
        render: {
          item: this.renderItem,
        },
      },
    );
  }

  disconnect() {
    this.select.destroy();
  }

  renderItem = (data, escape) => (`
    <div class="!bg-primary-8 rounded-full !px-2 !py-1">
      <p class="text-white font-semibold">
        ${escape(data.text)} 
      </p>
    <div>
  `)

  maxItems(element) {
    if (element.hasAttribute('data-items') && element.dataset.items == 'multiselect') {
      return null;
    }
    return 1;
  }

  clear() {
    this.select.clear()
  }

  selectedItem(element) {
    if (element.hasAttribute('selectedValue')) {
      return element.attributes.selectedValue.value;
    }
    if (element.hasAttribute('selectedValues')) {
      return element.attributes.selectedValues.value.split('_');
    }
    return null;
  }
}
