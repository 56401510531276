import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {

  change(event) {
    let id = event.target.id;

    patch(`/schools/tasks/${id}/done`, {
      responseKind: "turbo-stream"
    });
  }
}
