import { setLoading } from './ui_helpers';
import createElementsContainer from './components';
import { createSetupIntentContribute, confirmSetupIntent, checkSetupIntentStatus } from './api';

async function handleSubmit(event, elements, billingDetails, returnUrl, stripe) {
  event.preventDefault();

  setLoading(true);
  await confirmSetupIntent(elements, billingDetails, returnUrl, stripe);
  setLoading(false);
}

document.addEventListener('turbo:load', async () => {
  const contributionForm = document.querySelector('#contribution-form');

  if (contributionForm) {
    const stripe = Stripe(contributionForm.getAttribute('data-stripe-public-key'));
    const email = contributionForm.getAttribute('data-donation-email');
    const name = contributionForm.getAttribute('data-donation-name');

    const { clientSecret } = await createSetupIntentContribute(email, name);
    const elements = await createElementsContainer(stripe, clientSecret, email, name, false);
    checkSetupIntentStatus(stripe);

    const billingDetails = { email, name };
    const returnUrl = contributionForm.getAttribute('data-return-url');
    contributionForm.addEventListener('submit', (event) => handleSubmit(event, elements, billingDetails, returnUrl, stripe));
  }
});
