import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
export default class extends Controller {
  static targets = ["specificationSelect", "defaultImageSelect", "sportSelect", "assetId", "childIndex"]

  change(event) {
    this.sportSelectChanged(event.target)
  }

  sportSelectChanged(target) {
    const selectedOption = target.selectedOptions[0]
    const sportIsSelected = selectedOption && selectedOption.value && selectedOption.value != ""
    if (sportIsSelected) {
      const sportId = selectedOption.value

      const specificationsSelectId = this.specificationSelectTarget.id;
      const specificationSelectPresent = specificationsSelectId && specificationsSelectId != ""

      const assetTypeSelect = document.getElementById("template_asset_asset_type_id")
      let assetTypeId;
      if (assetTypeSelect) {
        assetTypeId = assetTypeSelect.value;
      }
      else {
        assetTypeId = document.getElementById("asset_asset_type_id").value
      }

      const assetTypeIsSelected = assetTypeId && (assetTypeId != "")
      if (specificationSelectPresent && assetTypeIsSelected) {
        this.loadDataAccordingToSelectedSport(sportId, specificationsSelectId, assetTypeId)
      };
    }
    else {
      const options = this.specificationSelectTarget.options;
      const length = options.length;
      for (let i = length; i >= 0; --i) {
        options.item(i)?.remove();
      }

      return;
    }
  }

  loadDataAccordingToSelectedSport(sportId, specificationsSelectId, assetTypeId) {
    const assetId = this.hasAssetIdTarget ? this.assetIdTarget.value : ""
    const assetIndex = this.hasChildIndexTarget ? this.childIndexTarget.value : ""

    const params = {
      specifications_select_id: specificationsSelectId,
      sport_id: sportId,
      asset_type_id: assetTypeId,
      asset_id: assetId,
      asset_index: assetIndex,
      current_specification_id: this.specificationSelectTarget.value
    };

    // Delete empty params
    Object.keys(params).forEach(key => params[key] === "" || params[key] === undefined && delete params[key]);
    const paramsString = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");

    get(`/specifications/sport_specifications?${paramsString}`, {
      responseKind: "turbo-stream"
    });
  }

  sportSelectTargetConnected(target) {
    this.sportSelectChanged(target)
  }

  selectDefaultImage(event) {
    event.preventDefault();

    let parentDiv = event.target.closest(".parent")
    let defaultImageId = parentDiv.getAttribute("default-image-id")
    let assetIndex = parentDiv.getAttribute("asset-index")
    let selectedDefaultImage = parentDiv.querySelector("img")
    this.toggleSelectImage(selectedDefaultImage, defaultImageId, assetIndex)
  }

  toggleSelectImage(selectedDefaultImage, defaultImageId, assetIndex) {
    let changesToSelected = !selectedDefaultImage.classList.contains("selected");
    this.toggleStyle(changesToSelected, defaultImageId, assetIndex)
    this.toggleInput(changesToSelected, selectedDefaultImage)
    changesToSelected ? selectedDefaultImage.classList.add("selected") : selectedDefaultImage.classList.remove("selected")
  }

  toggleStyle(changesToSelected, defaultImageId, assetIndex) {
    document.getElementById(`asset_${assetIndex}_check_${defaultImageId}`).hidden = !changesToSelected;
    document.getElementById(`asset_${assetIndex}_selected_div_${defaultImageId}`).hidden = !changesToSelected;
    let button = document.getElementById(`asset_${assetIndex}_button_${defaultImageId}`)
    if (changesToSelected) {
      button.classList.add("border-2");
      button.classList.remove("border-transparent");
      button.classList.add("border-primary-regular");
    } else {
      button.classList.remove("border-primary-regular");
      button.classList.add("border-transparent");
    }
  }

  toggleInput(changesToSelected, selectedDefaultImage) {
    let shouldBeDestroyedInput = selectedDefaultImage.parentElement.nextElementSibling;
    shouldBeDestroyedInput.value = changesToSelected ? "0" : "1";
  }
}
