import { get } from '@rails/request.js';

import { setLoading } from './ui_helpers';
import createElementsContainer from './components';
import { createSetupIntentCheckout, checkSetupIntentStatus, confirmSetupIntent } from './api';

async function handleSubmit(event, stripe, billingDetails, returnUrl, elements) {
  event.preventDefault();

  await elements.submit();

  setLoading(true);
  await confirmSetupIntent(elements, billingDetails, returnUrl, stripe);
  setLoading(false);

  get('/sponsr_place/payment_method', {
    responseKind: 'turbo-stream',
  });
}

document.addEventListener('turbo:frame-load', async () => {
  const paymentMethodForm = document.querySelector('#payment-method-form');

  if (paymentMethodForm) {
    const stripe = Stripe(paymentMethodForm.getAttribute('data-stripe-public-key'));

    const { clientSecret, email, name } = await createSetupIntentCheckout();

    const elements = await createElementsContainer(stripe, clientSecret, email, name, false);
    checkSetupIntentStatus(stripe);

    const billingDetails = { email, name };

    const returnUrl = paymentMethodForm.getAttribute('data-return-url');
    paymentMethodForm.addEventListener('submit', (event) => handleSubmit(event, stripe, billingDetails, returnUrl, elements));
  }
});
