import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  change(event) {
    let itemIds = JSON.parse(event.target.value)

    this.toggleCheckboxes(event.target.checked, itemIds)
  }

  toggleCheckboxes(value, itemIds) {
    let checkbox;
    let event;
    for (let i = 0; i < itemIds.length; i++) {
      checkbox = document.getElementById('legacy_sponsor_item_ids_' + itemIds[i])

      checkbox.checked = value
      event = new Event('change');
      checkbox.dispatchEvent(event);
    }
  }
}
