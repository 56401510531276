import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  change(event) {
    Object.values(document.getElementsByClassName('selected-image')).forEach(element => {
      element.classList.remove('selected-image');
      this.remove_selected_border(element.parentElement);
    })

    document.getElementById('large_image').src = event.target.src;

    event.target.classList.add('selected-image');
    this.add_selected_border(event.target.parentElement);
  }

  add_selected_border(element) {
    element.classList.add('border-2');
    element.classList.add('border-primary-5');
    element.classList.add('rounded');
    element.classList.add('p-px');
  }

  remove_selected_border(element) {
    element.classList.remove('border-2');
    element.classList.remove('border-primary-5');
    element.classList.remove('rounded');
    element.classList.remove('p-px');
  }
}
