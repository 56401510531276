import { setLoading } from './ui_helpers';
import createElementsContainer from './components';
import {
  createSetupIntentCheckout, createSetupIntentMarquee, confirmSetupIntent, checkSetupIntentStatus,
} from './api';

async function handleSubmit(event, elements, billingDetails, returnUrl, stripe) {
  event.preventDefault();

  setLoading(true);
  await confirmSetupIntent(elements, billingDetails, returnUrl, stripe);
  setLoading(false);
}

document.addEventListener('turbo:load', async () => {
  const regularPaymentForm = document.querySelector('#regular-payment-form');
  const marqueePaymentForm = document.querySelector('#marquee-payment-form');
  const paymentForm = regularPaymentForm || marqueePaymentForm;

  if (paymentForm) {
    const stripe = Stripe(paymentForm.getAttribute('data-stripe-public-key'));

    let endpointResponse;
    if (marqueePaymentForm) {
      endpointResponse = await createSetupIntentMarquee(marqueePaymentForm);
    } else {
      endpointResponse = await createSetupIntentCheckout();
    }

    const { clientSecret, email, name } = endpointResponse;

    const elements = await createElementsContainer(stripe, clientSecret, email, name, true);
    checkSetupIntentStatus(stripe);
    const billingDetails = { email, name };
    const returnUrl = paymentForm.getAttribute('data-return-url');
    paymentForm.addEventListener('submit', (event) => handleSubmit(event, elements, billingDetails, returnUrl, stripe));
  }
});
