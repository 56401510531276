import Alpine from 'alpinejs';

function creativeInput() {
  return {
    valueChanged(event) {
      const fileUploader = document.getElementsByClassName('uppy-DragDrop-container')[0];
      const submitButton = document.getElementById('submit')
      const checked = event.target.checked

      submitButton.disabled = !checked;
      fileUploader.disabled = checked;

      if(checked) {
        fileUploader.style.opacity = 0.5;
      } else {
        fileUploader.style.opacity = 1;
      };
    },
  }
}

Alpine.data('creativeInput', creativeInput);
