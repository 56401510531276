import { showMessage } from './ui_helpers';

export async function createSetupIntentCheckout() {
  const response = await fetch('/sponsor_place/setup_intents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
    },
  });
  return response.json();
}

export async function createSetupIntentContribute(email, name) {
  const response = await fetch('/sponsor_place/setup_intents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
    },
    body: JSON.stringify({ email, name }),
  });
  return response.json();
}

export async function createSetupIntentMarquee(form) {
  const email = form.getAttribute('data-guest-email');
  const name = form.getAttribute('data-guest-name');
  const relationshipWithSchool = form.getAttribute('data-guest-relationship-with-school');
  const pointOfContact = form.getAttribute('data-guest-point-of-contact');

  const response = await fetch('/sponsor_place/setup_intents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
    },
    body: JSON.stringify({
      email, name, relationshipWithSchool, pointOfContact,
    }),
  });
  return response.json();
}

function handleSetupIntentConfirmationError(error) {
  if (error.type === 'card_error' || error.type === 'validation_error') {
    showMessage(error.message);
  } else {
    showMessage('An unexpected error occurred.');
  }
}

export async function confirmSetupIntent(elements, billingDetails, returnUrl, stripe) {
  const confirmSetupIntentOptions = {
    elements,
    confirmParams: {
      return_url: returnUrl,
    },
    payment_method_data: {
      billing_details: billingDetails,
    },
  };
  const { error } = await stripe.confirmSetup(confirmSetupIntentOptions);

  handleSetupIntentConfirmationError(error);
}

export async function checkSetupIntentStatus(stripe) {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret',
  );

  if (!clientSecret) {
    return;
  }

  const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);

  switch (setupIntent.status) {
    case 'succeeded':
      showMessage('Your payment method has been saved.');
      break;
    case 'processing':
      showMessage("Processing payment details. We'll update you when processing is complete.");
      break;
    case 'requires_payment_method':
      showMessage('Failed to process payment details. Please try another payment method.');
      break;
    default:
      showMessage('Something went wrong.');
      break;
  }
}
