import Alpine from 'alpinejs';

function initializeSelectFields() {
  return {
    setClasses() {
      Array.from(document.getElementsByClassName('ts-wrapper')).forEach(function(wrapper) {
        wrapper.style.setProperty('width', '320px', 'important');
      });

      Array.from(document.getElementsByClassName('ts-control')).forEach(
        function(field) {
          field.style.setProperty('border-style', 'none', 'important');
          field.style.setProperty('border-radius', '0px', 'important');
          field.style.setProperty('margin', '0px', 'important');
        }
      );

      document.querySelectorAll("[id$=ts-control]").forEach(
        function (field) {
          field.style.setProperty('font-size', '24px', 'important');
          field.style.setProperty('font-weight', '800', 'important');
         }
      );
    },
  }
}

Alpine.data('initializeSelectFields', initializeSelectFields);
