import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { ids: Array }
  static targets = ["link"]

  connect() {
    this.ids = []
  }

  change(event) {
    let target = event.target;
    if (target.checked) {
      this.ids.push(target.id)
    } else {
      let index = this.ids.indexOf(target.id);
      this.ids.splice(index, 1);
    }

    const searchParams = new URLSearchParams();
    this.ids.forEach((id) => searchParams.append('unpublish_ids[]', id));

    this.linkTarget.href = `/schools/assets/confirm_unpublish?${searchParams.toString()}`
  }
}
