import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('recipients', {
    checkboxes: {},
  });
});

function selectRecipients(groupId) {
  return {
    selectAll: false,
    indeterminate: false,
    checkboxes: [],
    total: groupId?.querySelectorAll?.('input:not([disabled])[name^=recipients]')?.length,

    toggleRecipients() {
      const selectAll = !this.selectAll;

      const checkboxes = groupId.querySelectorAll('input:not([disabled])[name^=recipients]');
      [...checkboxes].map((el) => {
        el.checked = selectAll;
        const event = new Event('change');

        el.dispatchEvent(event);
      });
    },

    onValueChange(event) {
      const target = event.target;
      const value = target.checked;
      const id = target.value;

      this.checkboxes[id] = value;
      Alpine.store('recipients').checkboxes[id] = value;
      const length = Object.keys(this.checkboxes).filter((k) => this.checkboxes[k]).length;

      if (length === this.total) {
        this.selectAll = length === this.total;
        this.indeterminate = false;
      } else if (length > 0) {
        this.selectAll = false;
        this.indeterminate = true;
      } else {
        this.selectAll = false;
        this.indeterminate = false;
      }
    },
  };
}

Alpine.data('selectRecipients', selectRecipients);
