import Alpine from 'alpinejs';
import TomSelect from 'tom-select';

function initializeSelect(watcher) {
  return {
    select: '',
    maxItems(element) {
      if (element.hasAttribute('data-items') && element.dataset.items == 'multiselect') {
        return null;
      }
      return 1;
    },
    loadTomSelect() {
      this.select = new TomSelect(
        this.$el,
        {
          maxItems: this.maxItems(this.$el),
        },
      );
    },
    reloadTomSelect() {
      this.select.clear();
      this.select.clearOptions();
      this.select.sync();
    },
    init() {
      this.loadTomSelect()
      this.$watch(watcher, value => this.reloadTomSelect()) 
    }
  }
}

Alpine.data('initializeSelect', initializeSelect);
