import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    this.select = new TomSelect(
      this.element,
      {
        maxItems: this.maxItems(this.element),
        items: this.selectedItem(this.element),
      },
    );
  }

  disconnect() {
    this.select.destroy();
  }

  maxItems(element) {
    if (element.hasAttribute('data-items') && element.dataset.items == 'multiselect') {
      return null;
    }
    return 1;
  }

  selectedItem(element) {
    if (element.hasAttribute('selectedValue')) {
      return element.attributes.selectedValue.value;
    }
    if (element.hasAttribute('selectedValues')) {
      return element.attributes.selectedValues.value.split(' ');
    }
    return null;
  }
}
