import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('packages', {
    checkboxes: {},
  });
});

function selectPackageAssets(initialCheckedValue, assetId, price) {
  return {
    disabled: !initialCheckedValue,
    assetId,
    price,

    change(event) {
      const numberInput = document.getElementById(`package_package_assets_attributes_${this.assetId}_quantity`);
      const destroyInput = document.getElementById(`package_package_assets_attributes_${this.assetId}__destroy`);

      this.disabled = !event.target.checked;
      if (event.target.checked) {
        numberInput.setAttribute('min', 1);
        numberInput.value = 1;
        destroyInput.value = 'false';
        document.getElementById(`subtotal_${this.assetId}`).innerHTML = `$${price.toFixed(2)}`;
      } else {
        numberInput.setAttribute('min', 0);
        numberInput.value = 0;
        destroyInput.value = 'true';
        document.getElementById(`subtotal_${this.assetId}`).innerHTML = '$0.00';
      }
    },

    plus() {
      const input = document.getElementById(`package_package_assets_attributes_${this.assetId}_quantity`);
      input.value = parseInt(input.value, 10) + 1;
      document.getElementById(`subtotal_${this.assetId}`).innerHTML = `$${(price * input.value).toFixed(2)}`;
    },

    minus() {
      const input = document.getElementById(`package_package_assets_attributes_${this.assetId}_quantity`);
      if (input.value > 1) {
        input.value -= 1;
        document.getElementById(`subtotal_${this.assetId}`).innerHTML = `$${(price * input.value).toFixed(2)}`;
      }
    },
  };
}

function selectPackageDiscount(initialDiscount, initialIsPercentage) {
  return {
    percentage: initialIsPercentage,
    discount: initialDiscount,

    clickedPercentage(value) {
      const fullPrice = document.getElementById('package_inventory_price').value;

      if (this.discount == value) {
        document.getElementById('package_percentage_discount').value = '';
        document.getElementById('package_final_price').value = fullPrice;
        this.discount = '';
      } else {
        document.getElementById('package_percentage_discount').value = value;
        document.getElementById('package_final_price').value = (fullPrice * ((100 - value) / 100)).toFixed(2);
        document.getElementById('package_fixed_discount').value = '';
        this.discount = value;
        this.percentage = true;
        Alpine.store('packageSteps').valueChanged();
      }
    },

    changedFixedDiscount() {
      const fullPrice = document.getElementById('package_inventory_price').value;
      let discount = document.getElementById('package_fixed_discount').value;
      if (discount < 0) {
        document.getElementById('package_fixed_discount').value = null;
        discount = 0;
      }
      document.getElementById('package_final_price').value = (fullPrice - discount).toFixed(2);
      document.getElementById('package_percentage_discount').value = '';
    },

    changedPercentageDiscount() {
      const fullPrice = document.getElementById('package_inventory_price').value;
      let percentage = document.getElementById('package_percentage_discount').value;
      if (percentage < 0) {
        document.getElementById('package_percentage_discount').value = null;
        percentage = 0;
      }
      document.getElementById('package_final_price').value = (fullPrice - fullPrice * (percentage / 100)).toFixed(2);
      document.getElementById('package_fixed_discount').value = '';
    },

    resetPriceInput() {
      const fullPrice = document.getElementById('package_inventory_price').value;
      document.getElementById('package_final_price').value = fullPrice;
      document.getElementById('package_fixed_discount').value = '';
      document.getElementById('package_percentage_discount').value = '';
    },
  };
}

function selectAllPackagesData() {
  return {
    selectAll: false,
    anyPackageSelected: false,
    allCheckboxes: [],
    indeterminate: false,
    checkboxes: [],
    total: document.querySelectorAll?.('input:not([disabled])[name^=package]')?.length,

    toggleAllCheckboxes() {
      const selectAll = !this.selectAll;

      const checkboxes = document.querySelectorAll('input:not([disabled])[name^=package]');
      [...checkboxes].map((checkbox) => {
        checkbox.checked = selectAll;
        const event = new Event('change');
        checkbox.dispatchEvent(event);
      });
    },

    onValueChange(event) {
      const { target } = event;
      const value = target.checked;
      const id = target.value;

      this.checkboxes[id] = value;
      Alpine.store('packages').checkboxes[id] = value;
      const { length } = Object.keys(this.checkboxes).filter((k) => this.checkboxes[k]);

      if (length === this.total) {
        this.selectAll = length === this.total;
        this.indeterminate = false;
      } else if (length > 0) {
        this.selectAll = false;
        this.indeterminate = true;
      } else {
        this.selectAll = false;
        this.indeterminate = false;
      }
    },
  };
}

function trackChangesInPackages(isEvent) {
  return {
    isEvent: isEvent,
    trackInput: {
      ['@input']() {
        Alpine.store('packageSteps').valueChanged()
      }
    },
    trackChange: {
      ['@change']() {
        Alpine.store('packageSteps').valueChanged()
      }
    }
  }
}

Alpine.data('selectAllPackagesData', selectAllPackagesData);
Alpine.data('selectPackageAssets', selectPackageAssets);
Alpine.data('selectPackageDiscount', selectPackageDiscount);
Alpine.data('trackChangesInPackages', trackChangesInPackages)
