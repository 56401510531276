import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  change(event) {
    let input = event.target
    let n = this.destroyMask(input.value);
    input.setAttribute('data-normalized', n);
    input.value = this.createMask(n);
  }

  createMask(string){
    let length = string.length
    if (length < 0) {
      return '';
    } else if(length < 3) {
      return ('(' + string);
    } else if (length < 7) {
      return ('(' + string.substring(0,3) + ') ' + string.substring(3, length));
    } else if (length < 10) {
      return ('(' + string.substring(0,3) + ') ' + string.substring(3, 6) + '-' + string.substring(6, length));
    } else {
      return string.replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3");
    }
  }

  destroyMask(string){
    return string.replace(/\D/g,'').substring(0, 10);
  }
}
